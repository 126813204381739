import styled from 'styled-components';
import { FunctionComponent, useEffect, useState } from 'react';
import { Button } from '@primer/react';
import logo from '../../images/logo.png';
import { AccountMenu, CHANGE_MENU_WIDTH } from '../../basicComponents/accountMenu/AccountMenu';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import {
  MY_GAMES_PATH,
  REGISTRATION_PATH,
  PLANS_PATH,
  LOGIN_PATH,
  ON_BOARDING_PATH,
} from '../../utils/constants';
import { buttonStyle } from '../../theme/theme';
import { selectAuth } from '../../redux/slices/auth.slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getMenu, setMenu } from '../../redux/slices/menu.slice';
import { useNavigate } from 'react-router-dom';
import { MoreMenu } from './MoreMenu';
import { useGetSubscriptionStatusQuery } from '../../redux/apis/payment';
import { AddOnsMenu } from './AddOnsMenu';
import { useLogout } from '../../hooks/useLogout';

const PanelLeftBox = styled('div')`
  display: flex;
  flex-direction: row;
  justify-items: space-between;
  align-content: center;
  min-height: 50px;
`;

const Image = styled('img')<{ screenWidth: number }>`
  max-height: ${({ screenWidth }) => (screenWidth < CHANGE_MENU_WIDTH ? '32px' : '49px')};
  min-height: 45px;
  aspect-ratio: 5.4;
  padding-top: 10px;
  padding-bottom: 2px;
  padding-right: 15px;
  cursor: pointer;
`;

const BarMenuBox = styled('div')`
  height: 100%;
  display: flex;
  direction: row;
`;

const BarMenu = styled('a')<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? '#A792FF' : '#3b147a')};
  font-size: 17px;
  font-style: normal;
  font-weight: ${({ selected }) => (selected ? '600' : '400')};
  padding-top: 17px;
  padding-left: 20px;
  padding-right: 10px;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
`;

export const TopMenu: FunctionComponent = ({}) => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { isLoggedIn, isGuest } = useAppSelector(selectAuth);
  const [moreBallon, setMoreBallon] = useState(false);
  const [addOnBallon, setAddOnBallon] = useState(false);
  const sub = useGetSubscriptionStatusQuery(undefined, { skip: !isLoggedIn || isGuest });
  const dispatch = useAppDispatch();
  const selectedMenu = useAppSelector(getMenu);
  const logout = useLogout();

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const [leftPositionMoreMenu, setLeftPositionMoreMenu] = useState<number>(0);
  const [leftPositionAddOnsMenu, setLeftPositionAddOnsMenu] = useState<number>(0);

  const getLeftPositionMoreMenu = () => {
    const element = document.getElementById('more-menu');
    if (element) {
      const rect = element.getBoundingClientRect();
      setLeftPositionMoreMenu(rect.left);
    }
  };

  const getLeftPositionAddONMenu = () => {
    const element = document.getElementById('add-on');
    if (element) {
      const rect = element.getBoundingClientRect();
      setLeftPositionAddOnsMenu(rect.left);
    }
  };

  useEffect(() => {
    getLeftPositionMoreMenu();
    window.addEventListener('resize', getLeftPositionMoreMenu);
  }, []);

  useEffect(() => {
    getLeftPositionAddONMenu();
    window.addEventListener('resize', getLeftPositionAddONMenu);
  }, []);

  useEffect(() => {
    if (addOnBallon) setMoreBallon(false);
  }, [addOnBallon]);

  useEffect(() => {
    if (moreBallon) setAddOnBallon(false);
  }, [moreBallon]);

  return (
    <>
      <div
        style={{
          backgroundColor: 'white',
          boxShadow: '0px 0px 7px 0px black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          maxHeight: width > CHANGE_MENU_WIDTH ? '74px' : '60px',
          marginBottom: '50px',
          paddingRight: width > CHANGE_MENU_WIDTH ? '64px' : '16px',
          paddingLeft: width > CHANGE_MENU_WIDTH ? '64px' : '16px',
          top: '0px',
          width: '100%',
          zIndex: 1,
        }}
      >
        <PanelLeftBox>
          <Image
            alt="Cozy Juicy Real logo"
            onClick={() => {
              if (isGuest) {
                logout();
                navigate(LOGIN_PATH);
              } else {
                navigate('/');
              }
            }}
            screenWidth={width}
            src={logo}
          />
          <BarMenuBox>
            {isLoggedIn && !isGuest && width > CHANGE_MENU_WIDTH ? (
              <BarMenu
                onClick={() => {
                  navigate(MY_GAMES_PATH);
                  dispatch(setMenu('myGames'));
                }}
                selected={window.location.pathname == MY_GAMES_PATH}
              >
                My Games
              </BarMenu>
            ) : null}
            {width > CHANGE_MENU_WIDTH || isGuest ? (
              <BarMenu
                onClick={() => {
                  if (isGuest) return dispatch(setMenu('howToPlay'));
                  navigate(ON_BOARDING_PATH);
                  dispatch(setMenu('onBoarding'));
                }}
                selected={window.location.pathname == ON_BOARDING_PATH}
              >
                {isGuest ? 'Rules' : 'How to'}
              </BarMenu>
            ) : null}

            {width > CHANGE_MENU_WIDTH ? (
              <BarMenu
                id="add-on"
                onClick={() => {
                  if (
                    isLoggedIn &&
                    !isGuest &&
                    sub.data?.find(
                      (s) =>
                        s.isSubscriptionActive &&
                        !s.isTemporaryAddOnSubscription &&
                        s.plan !== 'FREE'
                    )
                  ) {
                    setAddOnBallon(true);
                  } else {
                    navigate(PLANS_PATH);
                  }
                }}
                selected={false}
              >
                {isLoggedIn && !isGuest
                  ? sub.data?.find(
                      (s) =>
                        s.isSubscriptionActive &&
                        !s.isTemporaryAddOnSubscription &&
                        s.plan !== 'FREE'
                    )
                    ? 'Add-ons'
                    : 'Upgrade'
                  : 'Plans & Pricing'}
              </BarMenu>
            ) : null}
            {width > CHANGE_MENU_WIDTH || isGuest ? (
              <>
                <BarMenu id="more-menu" onClick={() => setMoreBallon(true)} selected={false}>
                  More...
                </BarMenu>
              </>
            ) : null}
          </BarMenuBox>
        </PanelLeftBox>
        {window.location.pathname == REGISTRATION_PATH && width > CHANGE_MENU_WIDTH ? (
          <Button
            onClick={() => navigate(LOGIN_PATH)}
            size="medium"
            sx={{
              width: '100%',
              maxWidth: '110px',
              ...buttonStyle,
              margin: 'auto',
              marginRight: '0px',
              borderColor: '#5C37B5',
              color: '#5C37B5',
              background: '#FFF',
              marginTop: '10px',
              marginBottom: '10px',
            }}
            type="button"
            variant="primary"
          >
            Login
          </Button>
        ) : isLoggedIn && !isGuest ? (
          <AccountMenu />
        ) : width > CHANGE_MENU_WIDTH ? (
          <Button
            onClick={() => openInNewTab(`${window.location.origin}${REGISTRATION_PATH}`)}
            size="medium"
            sx={{
              width: '100%',
              maxWidth: '130px',
              ...buttonStyle,
              margin: 'auto',
              marginRight: '0px',
              borderColor: '#5C37B5',
              color: '#5C37B5',
              background: '#FFF',
              marginTop: '10px',
              marginBottom: '10px',
            }}
            type="button"
            variant="primary"
          >
            Sign up free
          </Button>
        ) : null}
      </div>
      {moreBallon ? (
        <MoreMenu leftPosition={leftPositionMoreMenu - 35} setClose={() => setMoreBallon(false)} />
      ) : null}
      {addOnBallon ? (
        <AddOnsMenu
          leftPosition={leftPositionAddOnsMenu - 35}
          setClose={() => setAddOnBallon(false)}
        />
      ) : null}
    </>
  );
};
