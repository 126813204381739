/**
 * This code was generated by Builder.io.
 */
import React from 'react';
import styled from 'styled-components';
import { TopMenu } from '../../layout/TopMenu';
import { BallonMenu } from '../../../basicComponents/accountMenu/BallonMenu';

const Header: React.FC = () => {
  return (
    <HeaderWrapper>
      <div style={{ position: 'absolute', top: '0px', left: '0px', width: '100%' }}>
        <TopMenu />
      </div>
      <BallonMenu />
      <HeaderContent>
        <HeaderTitle>Help your teams get cozy.</HeaderTitle>
        <HeaderSubtitle>Fun, easy, affordable and guaranteed to connect your teams</HeaderSubtitle>
      </HeaderContent>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled('header')`
  background: var(--Spark-Colors-Spark-2, #896dff);
  color: var(--White, #fff);
  text-align: center;
  padding: 152px 80px 84px;

  @media (max-width: 991px) {
    padding: 100px 20px 0;
  }
`;

const HeaderContent = styled('div')`
  max-width: 649px;
  margin: 0 auto;
`;

const HeaderTitle = styled('h1')`
  font-size: 52px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.72px;

  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const HeaderSubtitle = styled('p')`
  font-size: 18px;
  font-weight: 500;
  line-height: 2;
  opacity: 0.72;
  margin-top: 24px;
`;

export default Header;
