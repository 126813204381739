import React from 'react';
import styled from 'styled-components';
import { CJRButton as BaseCJRButton } from '../../../basicComponents/cjrButton/cjrButton';
import { REGISTRATION_PATH } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';

const TasterColumn: React.FC = () => {
  const navigate = useNavigate();
  return (
    <TasterContainer>
      <TasterTitle>Taster</TasterTitle>
      <FreeTag>FREE</FreeTag>
      <TasterDescription>Not sure yet? Test the game out with up to 4 players.</TasterDescription>
      <a href="/registration" rel="noopener noreferrer">
        <CustomCJRButton onClick={() => navigate(REGISTRATION_PATH)} variant="sparkTertiary">
          Sign up for free
        </CustomCJRButton>
      </a>
    </TasterContainer>
  );
};

const TasterContainer = styled('article')`
  border-radius: 20px;
  background-color: rgba(237, 233, 255, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Town 80 Text, sans-serif;
  color: var(--Dark, #333);
  font-weight: 700;
  text-align: center;
  width: 100%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  padding: 30px 30px;
  min-height: 340px;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
    padding: 30px 20px;
  }
`;

const TasterTitle = styled('h2')`
  font-size: 32px;
  line-height: 1;
  letter-spacing: -0.44px;
  margin-bottom: 15px;
`;

const FreeTag = styled('span')`
  border-radius: 16px;
  background-color: var(--Cozy-Colors-Cozy-4, #dfffed);
  color: var(--Cozy-Colors-Cozy-1, #004934);
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.86px;
  padding: 5px 10px;
`;

const TasterDescription = styled('p')`
  font-size: 16px;
  font-weight: 350;
  line-height: 2;
  margin: 30px;
`;

const CustomCJRButton = styled(BaseCJRButton)`
  margin-top: auto;
  border-radius: 30px; /* Override styles */
  background-color: var(--Spark-Colors-Spark-4, #ede9ff); /* Custom background */
  color: var(--Spark-Colors-Spark-1, #440d9b); /* Custom text color */
  font-size: 18px;
  font-weight: 700;
  padding: 10px 20px;

  /* Styles for the <a> tag */
  text-decoration: none;
  display: inline-block;
  color: inherit;
`;

export default TasterColumn;
