import React from 'react';
import styled from 'styled-components';
import { AddonFeature } from './AddonFeature';
import speech from '../../../images/speech.svg';

interface AddonCardProps {
  readonly title: string;
  readonly price?: {
    amount: string;
    currency: string;
  };
  readonly contactUs?: boolean;
  readonly features: string[];
}

const AddonCard: React.FC<AddonCardProps> = ({ title, price, contactUs, features }) => {
  return (
    <Card>
      <CardTitle>{title}</CardTitle>
      {price ? (
        <PriceWrapper>
          <FromText>From</FromText>
          <Currency>{price.currency}</Currency>
          <Amount>{price.amount}</Amount>
        </PriceWrapper>
      ) : null}
      {contactUs ? (
        <ContactUsWrapper>
          <ContactIcon src={speech} />
          <ContactText>Contact us</ContactText>
        </ContactUsWrapper>
      ) : null}
      <Divider />
      <FeatureList>
        <FeatureTitle>Add-on {contactUs ? 'includes' : 'options'}:</FeatureTitle>
        {features.map((feature, index) => (
          <AddonFeature key={index} text={feature} />
        ))}
      </FeatureList>
    </Card>
  );
};

const Card = styled('article')`
  border-radius: 20px;
  background-color: rgba(237, 233, 255, 0.6);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: 'Town 80 Text', sans-serif;
  color: var(--Dark, #333);
  padding: 40px 0;
  width: 100%;

  @media (max-width: 991px) {
    margin-top: 30px;
  }
`;

const CardTitle = styled('h3')`
  text-align: center;
  font-size: 28px;
  font-weight: 700;
`;

const PriceWrapper = styled('div')`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
  white-space: nowrap;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const FromText = styled('span')`
  font-size: 16px;
  font-weight: 350;
  margin-bottom: 24px;

  @media (max-width: 640px) {
    margin-bottom: 12px;
  }
`;

const Currency = styled('span')`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;

  @media (max-width: 640px) {
    margin-bottom: 12px;
  }
`;

const Amount = styled('span')`
  font-size: 64px;
  font-weight: 700;

  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const ContactUsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const ContactIcon = styled('img')`
  width: 70px;
  aspect-ratio: 0.98;
  object-fit: contain;
  margin-bottom: 24px;
`;

const ContactText = styled('span')`
  font-weight: 350;
  margin-bottom: 24px;
`;

const Divider = styled('hr')`
  background-color: rgba(153, 153, 153, 0.3);
  height: 1px;
  border: none;
  margin: 30px 0;
`;

const FeatureList = styled('ul')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  padding: 0 32px;
  list-style-type: none;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const FeatureTitle = styled('li')`
  font-weight: 700;
  margin-bottom: 25px;
`;

export default AddonCard;
