import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import checkIcon from '../../../images/pricingPage/checkIcon.svg';
import addonIcon from '../../../images/pricingPage/addonIcon.svg';
import crossIcon from '../../../images/pricingPage/close-circle.svg';
import infoIcon from '../../../images/pricingPage/infoIcon.svg';

// Initialize globalIndex outside the component
let activeTooltipIndex: number | null = null;

interface FeatureRowProps {
  readonly name: string;
  readonly proValue: boolean | string;
  readonly companyValue: boolean | string;
  readonly enterpriseValue: boolean | string;
  readonly highlighted?: boolean;
  readonly infoText?: string;
}

const FeatureRow: React.FC<FeatureRowProps> = ({
  name,
  proValue,
  companyValue,
  enterpriseValue,
  highlighted = false,
  infoText,
}) => {
  const [isTooltipActive, setIsTooltipActive] = useState(false);

  // Generate a unique identifier for each feature row as a number
  const uniqueId = parseInt(Math.random().toString().slice(2), 10);

  // Function to toggle the tooltip
  const handleToggleTooltip = () => {
    if (activeTooltipIndex === uniqueId) {
      activeTooltipIndex = null;
      setIsTooltipActive(false); // Close the tooltip
    } else {
      activeTooltipIndex = uniqueId;
      setIsTooltipActive(true); // Open the tooltip
    }
  };

  // Effect to close the tooltip when another one is opened
  useEffect(() => {
    const closeTooltipOnOutsideClick = () => {
      if (activeTooltipIndex !== uniqueId) {
        setIsTooltipActive(false);
      }
    };
    document.addEventListener('click', closeTooltipOnOutsideClick);
    return () => document.removeEventListener('click', closeTooltipOnOutsideClick);
  }, [uniqueId]);

  const renderValue = (value: boolean | string) => {
    if (typeof value === 'boolean') {
      return value ? <CheckIcon src={checkIcon} /> : <AddonIcon alt="Add-on" src={addonIcon} />;
    }
    if (value === 'cross') {
      return <CrossIcon alt="Not included" src={crossIcon} />;
    }
    return <span>{value}</span>;
  };

  return (
    <RowContainer highlighted={highlighted}>
      <FeatureName>
        {name}
        {infoText && (
          <InfoIconContainer
            onClick={handleToggleTooltip}
            onMouseEnter={() => window.innerWidth > 991 && setIsTooltipActive(true)} // Hover for desktop
            onMouseLeave={() => window.innerWidth > 991 && setIsTooltipActive(false)} // Hover for desktop
          >
            <InfoIcon alt="Info" src={infoIcon} />
            {isTooltipActive && <Tooltip>{infoText}</Tooltip>}
          </InfoIconContainer>
        )}
      </FeatureName>
      <FeatureValues>
        <FeatureValue backgroundColor="rgba(161, 139, 255, 0.05)">
          {renderValue(proValue)}
        </FeatureValue>
        <FeatureValue backgroundColor="rgba(161, 139, 255, 0.1)">
          {renderValue(companyValue)}
        </FeatureValue>
        <FeatureValue backgroundColor="rgba(161, 139, 255, 0.15)">
          {renderValue(enterpriseValue)}
        </FeatureValue>
      </FeatureValues>
    </RowContainer>
  );
};


// Styled components

const InfoIconContainer = styled('div')`
  position: relative;
  margin-left: auto;
  margin-right: 20px;
  cursor: pointer;

  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const InfoIcon = styled('img')`
  width: 20px;
  margin-left: 10px;
  margin-right: 5px;
`;

const Tooltip = styled('div')`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  white-space: normal;
  min-width: 300px;
  max-width: 400px;
  z-index: 1;
  opacity: 0.9;

  @media (max-width: 991px) {
    left: auto;
    top: -50px;
    right: 10px;
    transform: none;

  @media (max-width: 480px) {
    min-width: 180px;
    max-width: 300px;
  }
`;

const RowContainer = styled('div')<{ highlighted: boolean }>`
  display: flex;
  background-color: ${(props) => (props.highlighted ? '#f7f7f7' : '#fff')};
  min-height: 80px;

  @media (max-width: 991px) {
    flex-direction: column; /* Stack FeatureName and FeatureValues vertically on mobile */
    padding-top: 40px;
  }
`;

const FeatureName = styled('div')`
  color: #333;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  font: 500 16px 'Town 80 Text', sans-serif;
  width: 33%;
  margin-left: 30px;
  position: relative;

  @media (max-width: 991px) {
    margin-bottom: 10px;
    margin-left: 20px;
    gap: 10px;
    font: 500 15px 'Town 80 Text', sans-serif;
    width: 100%;
  }
`;

const FeatureValues = styled('div')`
  color: #333;
  display: flex;
  width: 67%;

  @media (max-width: 991px) {
    width: 100%;
    flex-direction: row; /* Ensure FeatureValues stay in a row */
    justify-content: space-between;
  }
`;

const FeatureValue = styled('div')<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font: 700 16px 'Town 80 Text', sans-serif;
  text-align: center;
  width: 35%;
  padding: 30px 10px;

  @media (max-width: 991px) {
    width: 100%;
    padding: 10px 0;
    min-height: 60px;
    font: 700 14px 'Town 80 Text', sans-serif;
  }
`;

const CheckIcon = styled('img')`
  width: 20px;
`;

const CrossIcon = styled('img')`
  width: 20px;
`;

const AddonIcon = styled('img')`
  width: 20px;
`;

export default FeatureRow;
